import {useEffect, useState} from "react"
import LatLng from "../types/LatLng"

const useLocation = (): LatLng|null => {
    const [userLocation, setUserLocation] = useState<LatLng|null>(null)

    useEffect(() => {
        // ask for the user permission to access geolocation api
        navigator.geolocation.watchPosition((geolocation) => {
            setUserLocation({
                lat: geolocation.coords.latitude,
                lng: geolocation.coords.longitude,
            })
        })
    }, [])
    
    return userLocation
}

export default useLocation
