import React, {useCallback, useEffect, useState} from 'react'
import {GlobalStyles} from "./styled"
import Map from './components/Map'
import {getGasStations} from "./modules/api"
import GasStation from "./types/GasStation"
import Bounds from "./types/Bounds"
import useLocation from "./hooks/useLocation"
import LatLng from "./types/LatLng"
import BottomSheet from "./components/BottomSheet"
import { LoadingContainer } from "./styled"

declare global {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    interface Window { google: any; }
}

const App: React.FC = () => {
    const [selectedGasStation, setSelectedGasStation] = useState<GasStation|null>(null)
    const [gasStationConfirmation, setGasStationConfirmation] = useState<GasStation|null>(null)
    const [dragging, setDragging] = useState<boolean>(false)
    const [bounds, setBounds] = useState<Bounds|null>(null)
    const [gasStationsList, setGasStationsList] = useState<GasStation[]>([])
    const userLocation: LatLng = useLocation()
    const [initialCenter, setInitialCenter] = useState<LatLng|null>(null)

    const handleBoundChanges = useCallback((newBounds: Bounds) => {
        setBounds(newBounds)
    }, [])

    const handleGasStationClick = useCallback((gasStation: GasStation) => {
        setGasStationConfirmation(gasStation)
    }, [])

    const handleStartRoute = useCallback((gasStation: GasStation) => {
        setSelectedGasStation(gasStation)
        setGasStationConfirmation(null)
    }, [])

    useEffect(() => {
        if(!bounds || dragging) return
        (async () => {
            setGasStationsList(await getGasStations(bounds))
        })()
     //eslint-disable-next-line
    }, [bounds])

    useEffect(() => {
        if(!initialCenter && !!userLocation) {
            setInitialCenter(userLocation)
        }
    //eslint-disable-next-line
    }, [userLocation])

    if(!userLocation) {
        return <LoadingContainer>Carregando localização...</LoadingContainer>
    }

    return (
        <>
            <GlobalStyles/>
            <Map
                userLocation={userLocation}
                initialCenter={initialCenter}
                gasStationsList={gasStationsList}
                onMapBoundsChange={handleBoundChanges}
                onDragStart={() => setDragging(true)}
                onDragEnd={() => setDragging(false)}
                onGasStationClick={handleGasStationClick}
                destination={selectedGasStation}
            />
            <BottomSheet
                visible={!!gasStationConfirmation}
                gasStation={gasStationConfirmation}
                onClose={() => setGasStationConfirmation(null)}
                onStartRoute={handleStartRoute}
            />
        </>
    )
}

export default App
