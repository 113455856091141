import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  position: relative;
  
  &::before {
    content: '';
    width: 32px;
    height: 4px;
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -16px;
    background: #E6E7F0;
    border-radius: 8px;
    top: 8px;
  }
`

export const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #4B4E5C;
  margin-bottom: 8px;
`

export const Address = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #8C91A9;
`

export const Button = styled.button`
  width: 220px;
  height: 48px;
  border-radius: 4px;
  background: linear-gradient(12.31deg, #FF500F 0%, #FF8700 100%);
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  border: none;
  outline: 0;
  color: white;
  margin: 24px;
`
