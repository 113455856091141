import React, {useState, memo, useCallback, useMemo} from 'react'
import {GoogleMap, useLoadScript, Marker, DirectionsService, DirectionsRenderer, InfoWindow} from "@react-google-maps/api"
import gsPin from "../../assets/svgs/gsPin.svg"
import userPin from "../../assets/svgs/userPin.svg"
import GasStation from "../../types/GasStation"
import LatLng from "../../types/LatLng"
import Bounds from "../../types/Bounds"

const containerStyle = {
    width: '100%',
    height: '100vh',
}

const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    minZoom: 13,
    streetViewControl: false,
    clickableIcons: false,
}

interface Props {
    userLocation: LatLng,
    initialCenter: LatLng,
    gasStationsList: GasStation[],
    onMapBoundsChange: (newBounds: Bounds) => void,
    onDragStart: () => void,
    onDragEnd: () => void,
    onGasStationClick: (gasStation: GasStation) => void,
    destination: GasStation|null,
}

const Map: React.FC<Props> = ({ userLocation, initialCenter, gasStationsList, onMapBoundsChange, onDragStart, onDragEnd, onGasStationClick, destination }) => {
    const [map, setMap] = useState(null)
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    })

    const onLoad = useCallback(function callback(googleMap) {
        console.log('Map loaded!')
        setMap(googleMap)
    }, [])

    const onUnmount = useCallback(function callback() {
        setMap(null)
    }, [])

    const handleBoundsChange = useCallback(() => {
        const bounds = map.getBounds()
        onMapBoundsChange(
            {
                northWest: {
                    lat: bounds.getSouthWest().lat(),
                    lng: bounds.getNorthEast().lng(),
                },
                southEast: {
                    lat: bounds.getNorthEast().lat(),
                    lng: bounds.getSouthWest().lng(),
                },
            },
        )
    }, [map, onMapBoundsChange])

    const directionsCallback = useCallback((response: { status: string }) => {
        if(response && response.status === 'OK')
            setDirectionsResponse(response)
    }, [])

    const directionsOptions = useMemo(() => ({
        directions: directionsResponse,
        draggable: true,
        suppressMarkers: true,
    }), [directionsResponse])

    const directionsServiceOptions = useMemo(() => ({
        destination: destination && destination.position,
        origin: userLocation,
        travelMode: 'DRIVING',
    }), [destination, userLocation])

    const gasStationsMarkers = useMemo(() => gasStationsList.map((gasStation: GasStation) => (
        <Marker
            key={`${gasStation.position.lat},${gasStation.position.lng}`}
            position={gasStation.position}
            icon={gsPin}
            onClick={() => onGasStationClick(gasStation)}
        />
    )), [gasStationsList, onGasStationClick])

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    const userLocationMarker = userLocation && (
        <Marker
            position={userLocation}
            icon={userPin}
        />
    )

    return isLoaded && (
         <GoogleMap
                options={mapOptions}
                mapContainerStyle={containerStyle}
                center={initialCenter}
                zoom={15}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onBoundsChanged={handleBoundsChange}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
            >
                <>
                    {gasStationsMarkers}
                    {userLocationMarker}
                    {destination && (
                        <>
                            <InfoWindow
                                position={destination.position}
                                options={{
                                    pixelOffset: new window.google.maps.Size(0, -50),
                                }}
                            >
                                <span>{destination.name}</span>
                            </InfoWindow>
                            <DirectionsService
                                options={directionsServiceOptions}
                                callback={directionsCallback}
                            />
                        </>
                    )}
                    {directionsResponse && (
                        <DirectionsRenderer
                            options={directionsOptions}
                        />
                    )}
                </>
            </GoogleMap>
    )
}

export default memo(Map)
