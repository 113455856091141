import React from 'react'
import { Modal } from "inter-frontend-lib-util"
import GasStation from "../../types/GasStation"
import { Container, Name, Address, Button} from "./styled"

interface Props {
    gasStation: GasStation|null,
    visible: boolean,
    onClose: () => void,
    onStartRoute: (gasStation: GasStation) => void,
}

const BottomSheet: React.FC<Props> = ({ visible, onStartRoute, onClose, gasStation }) => {
    return (
        <Modal
            triggerModal={visible}
            activeCloseModal
            callbackClose={onClose}
        >
            {gasStation && (
                <Container>
                    <Name>{gasStation.name}</Name>
                    <Address>{gasStation.address}</Address>
                    <Button type="button" onClick={() => onStartRoute(gasStation)}>
                        COMO CHEGAR
                    </Button>
                </Container>
            )}
        </Modal>
    )
}

export default BottomSheet
