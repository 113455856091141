import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html, body{
    padding: 0;
    margin: 0;
    font-family: 'Inter', Helvetica, sans-serif;
  }  
`
export const LoadingContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  align-items: center;
  color: #8C91A9;
  justify-content: center;
`
