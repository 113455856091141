import GasStation from "../types/GasStation"
import axios from 'axios'
import Bounds from "../types/Bounds"

const API_URL = process.env.REACT_APP_INTER_API_URL

export const getGasStations = async (bounds: Bounds): Promise<GasStation[]> => {
    const latPadding =  Math.abs(bounds.northWest.lat - bounds.southEast.lat) * 0.35
    const lngPadding =  Math.abs(bounds.northWest.lng - bounds.southEast.lng) * 0.35

    const start = `${(bounds.northWest.lat - latPadding).toFixed(8)},${(bounds.northWest.lng + lngPadding).toFixed(8)}`
    const end = `${(bounds.southEast.lat + latPadding).toFixed(8)},${(bounds.southEast.lng - lngPadding).toFixed(8)}`

    const response = await axios.get(`${API_URL}/shellbox/v1/postos?start=${start}&end=${end}`)
    const { data } = response
    return data.map((item: {lat: string, lng: string, name: string, address: string}): GasStation => ({
        ...item,
        position: {
            lat: parseFloat(item.lat),
            lng: parseFloat(item.lng),
        },
    }))
}
